import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import TextCurveIcon from '../../assets/icons/underline.svg';
import styles from './UnderlinedText.module.scss';

const UnderlinedTextColor = {
  ACCENT: 'accent',
  PRIMARY: 'primary',
};

const { ACCENT } = UnderlinedTextColor;

const MULTILINE_THRESHOLD = 2;

const UnderlinedText = ({ children, color, className }) => {
  const [isMultiline, setIsMultiline] = useState(false);
  const textRef = useRef();

  useEffect(() => {
    const { lineHeight } = getComputedStyle(textRef.current);
    const { height } = textRef.current.getBoundingClientRect();
    const lineHeightValue = parseFloat(lineHeight.split('px')[0]);

    setIsMultiline(Math.round(height / lineHeightValue) >= MULTILINE_THRESHOLD);
  }, []);

  return (
    <span ref={textRef} className={cx(styles.root, isMultiline && styles.block)}>
      <span className={styles.text}>{children}</span>
      <TextCurveIcon className={cx(styles.curve, styles[color], className)} />
    </span>
  );
};

UnderlinedText.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(UnderlinedTextColor)),
  className: PropTypes.string,
};

UnderlinedText.defaultProps = {
  color: ACCENT,
  className: undefined,
};

export { UnderlinedTextColor };
export default UnderlinedText;
