import { Text, Stack, Heading } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { NS_HOME } from 'constants/i18n';
import CarDamageIllustration from 'assets/illustrations/car-damage.svg';
import DamagedCarIllustration from 'assets/illustrations/damaged-car.svg';
import OdometerRollbackIllustration from 'assets/illustrations/odometer-rollback.svg';
import SuspiciousCarIllustration from 'assets/illustrations/suspicious-car.svg';
import type { Stat, StatId } from 'components/landings/types';
import styles from './StatCard.module.scss';

type StatCardProps = {
  stat: Stat;
};

const STAT_IMAGE_MAP: Record<StatId, React.ReactNode> = {
  accidents: <CarDamageIllustration original />,
  damage: <DamagedCarIllustration original />,
  history: <SuspiciousCarIllustration original />,
  inconsistencies: <OdometerRollbackIllustration original />,
};

const StatCard = ({ stat }: StatCardProps) => {
  const { t } = useTranslation(NS_HOME);
  const { id, percentage } = stat;

  return (
    <Stack className={styles.root} gap={{ mobileUp: 2, tabletPortraitUp: 4, desktopUp: 5 }}>
      <div className={styles.illustration}>{STAT_IMAGE_MAP[id]}</div>

      <Stack gap={0}>
        <Heading as="span" variant="m">
          {percentage} %
        </Heading>

        <Text as="span" variant="l">
          {t(`statsSection.stats.${id}`)}
        </Text>
      </Stack>
    </Stack>
  );
};

export { StatCard };
export type { StatCardProps };
