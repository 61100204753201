import { Container, Grid, Heading, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { type SectionProps, Section } from 'components/landings/common/Section';
import { NS_HOME } from 'constants/i18n';
import type { Stat, StatId } from 'components/landings/types';
import { StatCard } from './StatCard';
import styles from './StatsSection.module.scss';

type StatsSectionProps = {
  excludedStats?: StatId[];
  color?: SectionProps['color'];
};

const STATS: Stat[] = [
  {
    id: 'history',
    percentage: 60,
  },
  {
    id: 'accidents',
    percentage: 40,
  },
  {
    id: 'inconsistencies',
    percentage: 7,
  },
  {
    id: 'damage',
    percentage: 30,
  },
];

const StatsSection = ({ color = 'colorWhite', excludedStats }: StatsSectionProps) => {
  const { t } = useTranslation(NS_HOME);

  const stats = STATS.filter(({ id }) => !excludedStats?.includes(id));

  return (
    <Section color={color}>
      <Container>
        <Stack crossAxisAlign="stretch" gap={{ mobileUp: 6, desktopUp: 12 }}>
          <Grid columnCount={{ mobileUp: 1, desktopUp: 12 }} columnGap={0} rowGap={0}>
            <Heading className={styles.title} variant="l" as="h2">
              {t('statsSection.title')}
            </Heading>

            <Text className={styles.subtitle} variant="l">
              {t('statsSection.subtitle')}
            </Text>
          </Grid>

          <Grid
            columnCount={{ mobileUp: 1, desktopUp: 2 }}
            columnGap={{ mobileUp: 3, desktopUp: 5 }}
            rowGap={{ mobileUp: 3, desktopUp: 5 }}
          >
            {stats.map((stat) => (
              <StatCard key={stat.id} stat={stat} />
            ))}
          </Grid>
        </Stack>
      </Container>
    </Section>
  );
};

export { StatsSection };
export type { StatsSectionProps };
